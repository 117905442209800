






import { Component, Vue } from 'vue-property-decorator';
import MapView from './components/MapView.vue';

@Component({
  components: {
    MapView,
  },
})
export default class App extends Vue {}

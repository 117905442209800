





























































/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Component, Prop, Vue } from 'vue-property-decorator';
import StateBorders from '@/components/StateBorders.vue'
import { SvgMap } from "vue-svg-map";
import USAcounties from "@svg-maps/usa.counties";
import axios from 'axios';


interface MapLocation {
  id: string;
  name: string;
}

interface MapAttributes {
  id: {
    value: string;
  };
  name: {
    value: string;
  };
}

interface  MapTarget {
  attributes: MapAttributes;
}

@Component({
    components: {
        SvgMap,
        StateBorders,
    },
})
export default class MapView extends Vue {
  @Prop() msg!: string;
  pointedLocation = "";
  pointedLocationName = "";
  tooltipStyle: object = { display: "none" };
  USAcounties = USAcounties;
  rates = null;
  rateIndex = 0;
  maxIndex = 0;
  startDate = new Date(2020, 0, 1);
  animation: number | undefined = undefined;
  dataTimestamp: Date | null = null;

  get currentDate() {
    const d = new Date(this.startDate);
    d.setDate(d.getDate() + this.rateIndex);
    return d;
  }

  get currentDateString() {
    return this.currentDate.toLocaleString('en-US',
      {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
      });
  }

  get dataTimestampString() {
    const ts = this.dataTimestamp;
    if (ts) {
      return ts.toLocaleString('en-US',
        {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          hour: 'numeric',
          minute: 'numeric',
          day: 'numeric',
          timeZoneName: 'short',
        });
    } else {
      return "";
    }

  }

  get pointedRate() {
    const rates = this.rates;
    if (!rates || !this.pointedLocation) {
      return 0;
    }
    return rates[this.pointedLocation][this.rateIndex];
  }

  getLocationClass(location: MapLocation) {
    const rates = this.rates;
    if (!rates) {
      return `map-county severity-0`;
    }
    const rate = rates[location.id][this.rateIndex];
    const levels = [1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233];
    const nlevels = levels.length;
    let level = 0;
    for (level=0; level<nlevels && rate >= levels[level]; level++);
    const cls = `map-county severity-${level} `;
    return cls;
  }

  private getLocationId(eventtarget: EventTarget | null) {
    const target: MapTarget = eventtarget as unknown as MapTarget;
    return target.attributes.id.value;
  }

  private getLocationName(eventtarget: EventTarget | null) {
    if (eventtarget) {
      return (eventtarget as unknown as MapTarget)?.attributes?.name?.value;
    }
    return "";
  }

  private pointLocation(event: Event) {
    this.pointedLocation = this.getLocationId(event.target);
    this.pointedLocationName = this.getLocationName(event.target);
  }
    
  unpointLocation() {
    this.pointedLocation = "";
    this.pointedLocationName = "";
    this.tooltipStyle = { display: 'none' }
  }

  moveOnLocation(event: MouseEvent) {
    if(event?.target?.['id']) {
      const w = (window as any).visualViewport.width;
      const x = event.clientX;
      if (x <= w/2) {
        this.tooltipStyle = {
          display: 'block',
          top: `${event.clientY + 10}px`,
          left: `${event.clientX - 10}px`,
        }
      } else {
        this.tooltipStyle = {
          display: 'block',
          top: `${event.clientY + 10}px`,
          right: `${w - event.clientX - 10}px`,
        }
      }
    }
    event.stopPropagation();
  }

  baseMove() {
    this.unpointLocation();
  }

  onRateIndexChange(event: InputEvent) {
    this.rateIndex = (event.target as HTMLInputElement).valueAsNumber;
  }

  toggleAnimation() {
    if (this.animation != undefined) {
      clearInterval(this.animation);
      this.animation = undefined;
    } else {
      if (this.rateIndex == this.maxIndex-1) {
        this.rateIndex = 0;
      }
      this.animation = setInterval(() => {
        if (this.rateIndex == this.maxIndex-1) {
          clearInterval(this.animation);
          this.animation = undefined;
        } else {
          this.rateIndex = (this.rateIndex+1) % this.maxIndex;
        }
      }, 250);
    }
  }

  mounted() {
    // Add timestamp as a cache-prevention hack
    axios.get('/allrates.json' + '?' + new Date().getTime(),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        const rates = response.data.rates;
        const startdate = response.data.startdate;
        this.startDate = new Date(startdate);
        this.dataTimestamp = new Date(response.data.dataTimestamp);
        if (rates ) {
          this.rates = rates;
          this.maxIndex = rates['worcester-ma'].length;
          this.toggleAnimation();
        }
    });
  }
}

